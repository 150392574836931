import React, { useEffect, useState } from "react"
import "./scss/PrivacyPolicyStatement.scss"
import { graphql, useStaticQuery } from "gatsby"
import Aos from "aos";
import "aos/dist/aos.css";

export default function PrivPol() {

  useEffect(() => {
    Aos.init({duration: 1000});
  }, []);

  return (
    <div className="contentFeaturep">
      <div className={'textContainerp'}>
        <p className={'titlep'}>The Fresh Kid LTD Privacy Policy</p>
        <br></br>
        <p className='bodyp'> This privacy policy will explain how our organization uses the personal data we collect from you when you use our website.</p>
        <br></br>
        <p className='bodyp'> Topics:</p>
        <br></br>
        <div class="policyList">
          <ul>
              <li class='bullet2'>What data do we collect?</li>
              <li class='bullet2'>How do we collect your data?</li>
              <li class='bullet2'>How will we use your data?</li>
              <li class='bullet2'>How do we store your data?</li>
              <li class='bullet2'>Marketing</li>
              <li class='bullet2'>What are your data protection rights?</li>
              <li class='bullet2'>What are cookies?</li>
              <li class='bullet2'>How do we use cookies?</li>
              <li class='bullet2'>What types of cookies do we use?</li>
              <li class='bullet2'>How to manage your cookies</li>
              <li class='bullet2'>Privacy policies of other websites</li>
              <li class='bullet2'>Changes to our privacy policy</li>
              <li class='bullet2'>How to contact us</li>
              <li class='bullet2'>How to contact the appropriate authorities</li>
        </ul>
      </div>
        <p className={'subTitlep'}>What data do we collect?</p>
        <br></br>
        <p className='bodyp'> The Fresh Kid LTD collects the following data:</p>
        <br></br>
        <div class="policyList">
          <ul>
              <li class='bullet2'>Personal identification information (Name, email address and phone number)</li>
          </ul>
        </div>
        <p className='subTitlep'> How do we collect your data?</p>
        <br></br>
        <p className='bodyp'> You directly provide The Fresh Kid LTD with most of the data we collect. We collect data and process data when you:</p>
        <br></br>
        <div class="policyList">
          <ul>
              <li class='bullet2'>Register online or place an order for any of our products or services.</li>
              <li class='bullet2'>Voluntarily complete a customer survey or provide feedback on any of our message boards or via email.</li>
              <li class='bullet2'>Use or view our website via your browser<span class="apostrophe">'</span>s cookies.</li>
          </ul>
        </div>
        {/* <p className='bodyp'> The Fresh Kid LTD may also receive your data indirectly from the following sources:</p>
        <br></br>
        <ul>
            <li>[Add any indirect source of data The Fresh Kid LTD has]</li>
	    </ul> */}
        <p className='subTitlep'> How will we use your data?</p>
        <br></br>
        <p className='bodyp'> The Fresh Kid LTD collects your data so that we can:</p>
        <br></br>
        <div class="policyList">
          <ul>
              <li class='bullet2'>Process your order and manage your account.</li>
              <li class='bullet2'>Email you with special offers on other products and services we think you might like.</li>
          </ul>
        </div>
        {/* <p className='bodyp'> If you agree, The Fresh Kid LTD will share your data with our partner companies so that they may offer you their products and services:</p>
        <br></br>
        <ul>
            <li>[List organizations that will receive data]</li>
	    </ul> */}
        {/* <p className='sentencep'> When The Fresh Kid LTD processes your order, it may send your data to, and also use the resulting information from, credit reference agencies to prevent fraudulent purchases.</p> */}
        {/* <br></br> */}
        <p className='subTitlep'> How do we store your data?</p>
        <br></br>
        <p className='sentencep'> <strong>The Fresh Kid LTD securely stores your data at [enter the location and describe security precautions taken].</strong></p>
        <br></br>
        <p className='sentencep'> <strong>The Fresh Kid LTD will keep your [enter type of data] for [enter time period]. Once this time period has expired, we will delete your data by [enter how you delete users’ data].</strong></p>
        <br></br>
        <p className='subTitlep'> Marketing</p>
        <br></br>
        {/* <p className='bodyp'> The Fresh Kid LTD would like to send you information about products and services of ours that we think you might like, as well as those of our partner companies:</p> */}
        <p className='bodyp'> The Fresh Kid LTD would like to send you information about products and services of ours that we think you might like.</p>
        <br></br>
        {/* <ul>
            <li>[List organizations that will receive data]</li>
	    </ul> */}
        <p className='sentencep'> If you have agreed to receive marketing, you may always opt out at a later date.</p>
        <br></br>
        <p className='sentencep'> You have the right at any time to stop The Fresh Kid LTD from contacting you for marketing purposes or giving your data to other members of the The Fresh Kid LTD Group.</p>
        <br></br>
        <p className='sentencep'> If you no longer wish to be contacted for marketing purposes, please click here.</p>
        <br></br>
        <p className='subTitlep'> What are your data protection rights?</p>
        <br></br>
        <p className='bodyp'> The Fresh Kid LTD would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:</p>
        <br></br>
        <div class="policyList">
          <ul>
              <li class='bullet2'>The right to access: You have the right to request The Fresh Kid LTD for copies of your personal data. We may charge you a small fee for this service.</li>
              <li class='bullet2'>The right to rectification: You have the right to request that The Fresh Kid LTD correct any information you believe is inaccurate. You also have the right to request The Fresh Kid LTD to complete the information you believe is incomplete.</li>
              <li class='bullet2'>The right to erasure: You have the right to request that The Fresh Kid LTD erase your personal data, under certain conditions.</li>
              <li class='bullet2'>The right to restrict processing: You have the right to request that The Fresh Kid LTD restrict the processing of your personal data, under certain conditions.</li>
              <li class='bullet2'>The right to object to processing: You have the right to object to The Fresh Kid LTD<span class="apostrophe">'</span>s processing of your personal data, under certain conditions.</li>
              <li class='bullet2'>The right to data portability: You have the right to request that The Fresh Kid LTD transfer the data that we have collected to another organization, or directly to you, under certain conditions.</li>
              <li class='bullet2'>If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us at our email:</li>
              {/* <li>Call us at:</li>
              <li>Or write to us:</li> */}
          </ul>
        </div>
        <br></br>
        <p className='subTitlep'> Cookies</p>
        <br></br>
        <p className='sentencep'> Cookies are text files placed on your computer to collect standard Internet log information and visitor behavior information. When you visit our websites, we may collect information from you automatically through cookies or similar technology</p>
        <br></br>
        <p className='sentencep'> For further information, visit allaboutcookies.org.</p>
        <br></br>
        <p className='subTitlep'> How do we use cookies?</p>
        <br></br>
        <p className='bodyp'> The Fresh Kid LTD uses cookies in a range of ways to improve your experience on our website, including:</p>
        <br></br>
        <div class="policyList">
          <ul>
              <li class='bullet2'>Keeping you signed in</li>
              <li class='bullet2'>Understanding how you use our website</li>
              {/* <li>[Add any uses The Fresh Kid LTD has for cookies]</li> */}
          </ul>
        </div>
        <p className='subTitlep'> What types of cookies do we use?</p>
        <br></br>
        <p className='bodyp'> There are a number of different types of cookies, however, our website uses:</p>
        <br></br>
        <div class="policyList">
          <ul>
              <li class='bullet2'>Functionality: The Fresh Kid LTD uses these cookies so that we recognize you on our website and remember your previously selected preferences. These could include what language you prefer and location you are in. A mix of first-party and third-party cookies are used.</li>
              <li class='bullet2'>Advertising: The Fresh Kid LTD uses these cookies to collect information about your visit to our website, the content you viewed, the links you followed and information about your browser, device, and your IP address. The Fresh Kid LTD sometimes shares some limited aspects of this data with third parties for advertising purposes. We may also share online data collected through cookies with our advertising partners. This means that when you visit another website, you may be shown advertising based on your browsing patterns on our website.</li>
              {/* <li>[Add any other types of cookies The Fresh Kid LTD uses]</li> */}
          </ul>
        </div>
        <br></br>
        <p className='subTitlep'> How to manage cookies</p>
        <br></br>
        <p className='sentencep'>You can set your browser not to accept cookies, and the above website tells you how to remove cookies from your browser. However, in a few cases, some of our website features may not function as a result.</p>
        <br></br>
        <p className='subTitlep'> Privacy policies of other websites</p>
        <br></br>
        <p className='sentencep'> The Fresh Kid LTD website contains links to other websites. Our privacy policy applies only to our website, so if you click on a link to another website, you should read their privacy policy.</p>
        <br></br>
        <p className='subTitlep'> Changes to our privacy policy</p>
        <br></br>
        <p className='sentencep'>The Fresh Kid LTD keeps its privacy policy under regular review and places any updates on this web page. This privacy policy was last updated on 26 April 2023.</p>
        <br></br>
        <p className='subTitlep'> How to contact us</p>
        <br></br>
        <p className='sentencep'>If you have any questions about The Fresh Kid LTD<span class="apostrophe">'</span>s privacy policy, the data we hold on you, or you would like to exercise one of your data protection rights, please do not hesitate to contact us.</p>
        <br></br>
        <p className='sentencep'>Email us at: hey@thefreshkid.com</p>
        {/* <br></br>
        <p className='sentencep'>Call us:</p>
        <br></br>
        <p className='sentencep'>Or write to us at:</p> */}
        <br></br>
        <p className='subTitlep'> How to contact the appropriate authority</p>
        <br></br>
        <p className='sentencep'>Should you wish to report a complaint or if you feel that The Fresh Kid LTD has not addressed your concern in a satisfactory manner, you may contact the Information Commissioner<span class="apostrophe">'</span>s Office.</p>
        {/* <br></br>
        <p className='sentencep'>Email: </p>
        <br></br>
        <p className='sentencep'>Address:</p> */}
      </div>
    </div>
  )
}