import * as React from "react"
// import "./index.css"

import PrivPol from "../components/PrivacyPolicyStatement"
import Layout from "../components/Layout"

function PrivacyPolicy({ data }) {
  return (
    <Layout>
      <PrivPol />
      {/* <p> .</p>
      <p> .</p>
      <p> .</p>
      <p> .</p>
      <p> .</p>
      <p> .</p>
      <p> .</p>
      <p> .</p>
      <p> .</p>
      <p> .</p>
      <p> .</p>
      <p> .</p> */}
    </Layout>
    
  )
}

export default PrivacyPolicy