import React, { useState, useEffect } from "react";
import "./scss/FeatureBar.scss";
import { graphql, useStaticQuery } from "gatsby";
import { StaticImage } from "gatsby-plugin-image"

export default function MarqueBarBlog() {
  const [isVisible, setIsVisible] = useState(true);
  const data = useStaticQuery(graphql`
  query MyQuery92 {
    allContentfulMarqueBar {
      edges {
        node {
          marqueLogo {
            file {
              url
            }
          }
        }
      }
    }
  }
  `);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 800) {
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Access the second entry in the `edges` array by index 1
  const secondEntry = data.allContentfulMarqueBar.edges[0];

  return (
<div>
      <nav className={`featureBar${isVisible ? "" : " fade-out"}`}>
        <div className="MarqueBox">
          {secondEntry && (
            // <img
            //   className="MarqueLogo rotateClockwise" // Add the rotatingImage class
            //   alt=""
            //   src={secondEntry.node.marqueLogo.file.url}
            // />
            <StaticImage className={"MarqueLogo rotateClockwise"} 
        src="https://images.ctfassets.net/x5jqyuhgi4rx/6bw715nFsUVXOqDEgAxuK8/68020b4c5f1965b3e7e0a4ce9cc130c4/marque_blackk.webp"
        alt=""/>
          )}
        </div>
      </nav>
    </div>
  );
}
